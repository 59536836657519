<template>
  <div>
    <div class="table-input">
      <Input v-model="param.name" clearable placeholder="昵称、手机号" />
      <Button type="primary" @click="search()">搜索</Button>
    </div>
    <Table :columns="columns" :data="data" @on-selection-change="changeSelection">

      <template slot-scope="{ row, index }" slot="url">
        <img :src="row.url" style="width: 30px;height: 30px;margin:5px 5px 0 5px;cursor: zoom-in;" @click="MyUtil.showBigImg(row.url)" />
      </template>

      <template slot-scope="{ row, index }" slot="headUrl">
        <img :src="item" style="width: 30px;height: 30px;margin:5px 5px 0 5px;cursor: zoom-in;" v-for="item,index in row.headUrlArr" :key="index" @click="MyUtil.showBigImg(item)" />
      </template>

      <template slot-scope="{ row, index }" slot="payMethod">
        <span v-if="row.payMethod==0" style="color:green">平台代收</span>
        <span v-if="row.payMethod==1" style="color:red">商家自收</span>
      </template>

      <template slot-scope="{ row, index }" slot="status">
        <span v-if="row.status==0" style="color:green">启用</span>
        <span v-if="row.status==1" style="color:red">禁用</span>
      </template>

      <template slot-scope="{ row, index }" slot="action">
        <!-- <Button v-if="row.payMethod==0" type="primary" size="small" @click="toPayMethod(row)">商家自收</Button>
        <Button v-if="row.payMethod==1" type="primary" size="small" @click="toPayMethod(row)">平台代收</Button> -->
        <Button v-if="row.status==0" type="primary" size="small" @click="toStatus(row)">禁用</Button>
        <Button v-if="row.status==1" type="primary" size="small" @click="toStatus(row)">启用</Button>
      </template>
    </Table>
    <Page :total="total" :current="param.page" :page-size="param.rows" show-total show-sizer @on-change="changePage" @on-page-size-change="changePageSize"></Page>

  </div>
</template>

<script>
export default {
  data() {
    return {
      MyUtil: this.MyUtil,
      //列表
      deleteIds: [],
      param: {
        name: null,
        page: 1,
        rows: 10,
      },
      total: 0,
      data: [],
      columns: [
        {
          type: "selection",
          width: 35,
        },
        {
          title: "ID",
          key: "id",
          width: 100,
        },
        {
          title: "昵称",
          key: "nickname",
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "手机号码",
          key: "phone",
        },
        {
          title: "微信号",
          key: "wxCode",
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "头像",
          slot: "url",
        },
        // {
        //   title: "收款方式",
        //   slot: "payMethod",
        // },
        {
          title: "状态",
          slot: "status",
          width: 100,
        },
        {
          title: "操作",
          slot: "action",
          fixed: "right",
          width: 100,
        },
      ],
    };
  },
  methods: {
    getDataList() {
      this.MyUtil.ajax()
        .post("/UserController/getUserList", this.param)
        .then((resData) => {
          this.data = resData.data;
          this.total = resData.total;
          if (this.data) {
            this.data.forEach((element) => {
              if (element.headUrl) {
                element.headUrlArr = element.headUrl.split(",");
              }
            });
          }
        });
    },
    changePage(page) {
      this.param.page = page;
      this.getDataList();
    },
    changePageSize(rows) {
      this.param.rows = rows;
      this.getDataList();
    },
    changeSelection(selection) {
      this.deleteIds = [];
      selection.forEach((element) => {
        this.deleteIds.push(element.id);
      });
    },
    search() {
      this.param.page = 1;
      this.getDataList();
    },

    toStatus(row) {
      this.$Modal.confirm({
        title: "操作确认",
        content: "点击确认，以继续执行该操作！",
        onOk: () => {
          this.MyUtil.ajax()
            .post("/UserController/updateUserStatus", {
              id: row.id,
            })
            .then((resData) => {
              this.$Message.success("操作成功");
              this.getDataList();
            });
        },
      });
    },

    toPayMethod(row) {
      this.$Modal.confirm({
        title: "操作确认",
        content: "点击确认，以继续执行该操作！",
        onOk: () => {
          this.MyUtil.ajax()
            .post("/UserController/updateUserPayMethod", {
              id: row.id,
            })
            .then((resData) => {
              this.$Message.success("操作成功");
              this.getDataList();
            });
        },
      });
    },
  },
  created() {
    this.getDataList();
  },
};
</script>
